import React, { Component } from "react";
import axios from "axios";
import FreelyLogo from "../images/new/freely-logo-footer.svg";
import Fade from "react-reveal/Fade";
import SocialIcons from "./social";
import { Link } from "gatsby";
const APP_ID = "app6hjtthc9HexT2e";
const TABLE = "Registrations";
const APP_key = "keybF8EdD3bYNQpHC";

export default class Suscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
      value: "",
    };
  }
  handleChange(e) {
    const { target } = e;
    const { value } = target;
    this.setState(
      {
        value: value,
      },
      () => {
        console.log(this.state.value);
      }
    );
  }

  handleSubmit = event => {
    event.preventDefault();
    const data = {
      records: [
        {
          fields: {
            Email: this.state.value,
            "Date Registered": new Date()
              .toISOString()
              .slice(0, 19)
              .replace(/-/g, "/")
              .replace("T", " "),
          },
        },
      ],
    };

    const URL = "https://api.airtable.com/v0/" + APP_ID + "/" + TABLE;
    const axiosConfig = {
      headers: {
        Authorization: "Bearer " + APP_key,
        "Content-Type": "application/json",
      },
    };

    axios
      .post(URL, data, axiosConfig)
      .then(response => {
        this.setState({
          data: response,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { email, loadingOverlay, showForm } = this.state;

    return (
      <div id="suscribe">
        <div className="md:container  md:mx-auto bg-freelyCream">
          <div className="flex flex-col lg:flex-row-reverse ">
            <div className="lg:w-3/4 bg-freelyBlue-dark  px-6 py-8 text-lg  front-bold text-white">
              <div className="flex flex-col lg:flex-row xs:text-center ">
                <Fade delay={400}>
                  <div className="lg:w-1/3 lg:p-6 xl:p-10">
                    <h5>Get Social</h5>

                    <SocialIcons />
                  </div>

                  <div className="lg:w-2/3 lg:p-6 xl:p-10">
                    <p className="py-4 lg:py-0">
                      Subscribe to the Freely Newsletter...
                    </p>
                    {!this.state.data && (
                      <>
                        <form
                          className=" flex  justify-center items-center flex-row  lg:py-6"
                          onSubmit={this.handleSubmit}
                        >
                          <input
                            name="email"
                            type="email"
                            className="w-9/12 mt-2 text-white bg-freelyBlue-dark px-3 py-5 lg:mt-0"
                            value={this.state.value}
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            placeholder="Enter your email to stay up to date...."
                          />
                          <button
                            type="submit"
                            className=" w-3/12 mx-auto bg-freelyBlue-light font-bold py-5  mt-2 uppercase lg:mt-0"
                          >
                            Submit{" "}
                          </button>
                        </form>
                      </>
                    )}
                    {this.state.data && (
                      <Fade delay={600}>
                        <p className="w-9/12 mt-2 text-white bg-freelyBlue-dark  py-6 lg:mt-0">
                          Thank you! We will be in touch with you soon.
                        </p>
                      </Fade>
                    )}
                  </div>
                </Fade>
              </div>
              <div></div>
            </div>
            <Fade delay="400">
              <div className="bg-freelyCream  px-6 lg:w-1/4 py-12 flex justify-center xs:hidden">
                {" "}
                <Link className=" w-full flex justify-center" to="/">
                  {" "}
                  <img src={FreelyLogo} />
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    );
  }
}
