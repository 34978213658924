import React from "react";
import FacebookIcon from "../images/new/facebook.svg";
import InstagramIcon from "../images/new/instagram.svg";
import TwitterIcon from "../images/new/twitter.svg";
import LinkedinIcon from "../images/new/linkedin.svg";
import { useStaticQuery, graphql } from "gatsby";

export default function Social({ icons }) {
  const { social } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter
              facebook
              instagram
              linkedin
            }
          }
        }
      }
    `
  ).site.siteMetadata;

  return (
    <div className="flex py-6 icons xs:justify-center ">
      <a target="_blank" rel="noopener noreferrer" href={social.facebook}>
        <img className={icons} src={FacebookIcon} alt="facebook" />
      </a>
      <a target="_blank" rel="noopener noreferrer" href={social.twitter}>
        <img src={TwitterIcon} alt="twitter" />
      </a>
      <a target="_blank" rel="noopener noreferrer" href={social.instagram}>
        <img src={InstagramIcon} alt="instagram" />
      </a>
      <a target="_blank" rel="noopener noreferrer" href={social.linkedin}>
        <img src={LinkedinIcon} alt="linkedin" />
      </a>
    </div>
  );
}
